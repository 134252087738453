import * as React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';
import { Button } from '../../../Molecules/Button/Button';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './TextVisuelCTATwoCol.scss';
import { trackPageCTAEvents } from '../../../Utils/Tracking';

let classNames = require('classnames');

const TextVisuelCTATwoCol = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  title,
  text,
  orderInverse,
  alignTop,
  alignLeft,
  cta,
  smallMarginBottom,
  listiconArrow,
  textColor = ''
}) => {
  const { processUrl } = useMedias();

  const { titleText, subtitle } = title || {};
  const { ctaText, ctaLink } = cta || {};

  const onClickEvent = (e) => {
    if (e.currentTarget.closest('.page_template').classList.contains('page_activities')) {
      let activity = e.currentTarget.closest('.section_content').querySelector('.title_section h2').textContent
      trackPageCTAEvents({
        event: 'clic_cta',
        page_name: 'nos_activites',
        page_type: 'groupe',
        activity: activity,
        button_content: 'decouvrir_lactualite',
        button_type: 'cta'
      })
    }

  }

  return (
    <div
      className={classNames('text_visuel_cta_two_col_component', {
        small_margin_bottom: smallMarginBottom,
      })}
    >
      <div
        className={classNames({
          cross_wrapper_extreme_right: extremeRight,
          cross_wrapper_extreme_left: extremeLeft,
        })}
      >
        <div
          className={classNames(
            'text_visuel',
            { order_inverse: orderInverse },
            { align_top: alignTop },
            { align_left: alignLeft }
          )}
        >
          <div className="title_top only_mobile_tablet">
            {title && <TitleSection title={titleText} />}
          </div>
          {
            visuel &&
            <div className={classNames('visuel')}>
              <img
                src={processUrl(visuel?.image_style_uri?.gatsby_medium)}
                alt={alt}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </div>
          }

          <div className="text_container">
            <div className="text_center">
              {title && <TitleSection title={titleText} h2color={textColor} />}
              {subtitle && (
                <div
                  className="big_text"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></div>
              )}

              <div
                className={classNames(listiconArrow ? 'arrowlist' : '', 'color_' + textColor)}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
              {cta?.ctaLink && (
                <div className="btn_container">
                  <Button
                    label={ctaText}
                    link={ctaLink}
                    aria-label={ctaText + ' - ' + titleText}
                    onClickEvent={onClickEvent}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TextVisuelCTATwoCol.defaultProps = {
  text: '',
  alt: '',
};

TextVisuelCTATwoCol.propTypes = {
  title: PropTypes.object,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool,
  cta: PropTypes.object,
  alt: PropTypes.string,
};

export default TextVisuelCTATwoCol;
